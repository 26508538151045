.rect-box {
    height: 100px;
    min-width: 250px;
    width: 50%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(30, 147, 253, 0.20);
  }
  
  .title {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  
  .number {
    text-align: center;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    background-image: linear-gradient(45deg, #1E93FD, #3153C7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
  }
  
  hr {
    background: #C9C9C9;
    width: 100%; /* Set the width to 100% */
    margin: 0; /* Remove the default margin */
  }
  