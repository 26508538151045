/* General styling for each client item row */

.data-client-item-1{
  flex: 1;
  text-align: left;
  padding: 0 10px;
}

.data-client-item-2{
  flex: 1;
  text-align: left;
  padding: 0 10px;
}

.data-client-item-3{
  flex: 1;
  text-align: left;
  padding: 0 10px;
}

.client-list-item {
  display: flex;
  align-items: center; /* Keeps items aligned vertically */
  justify-content: space-between; /* Distributes space between elements */
  padding: 10px 50px;
  border-bottom: 1px solid #C9C9C9;
}

.client-list-item.grey {
  background-color: #F5F5F5;
}

/* Adjusting for element alignment */
.client-list-item > div {
  display: flex;
  align-items: center; /* This will vertically center the contents */
  justify-content: center; /* Horizontally center the content for elements 2 and 3 */
}

/* Keep the widths as defined */
.client-list-item > div:nth-child(1),
.client-list-item > div:nth-child(2),
.client-list-item > div:nth-child(3) {
  width: calc(60% / 3);
}

.client-list-item > div:nth-child(4) {
  width: 40%;
}

/* Additional styles unchanged */


/* Style for action buttons to ensure they don't overflow the container */
/* Style for the select element to ensure it has rounded corners */
select {
  /* Your existing select styles */
  margin: 0; /* Ensure there's no extra margin that could disrupt alignment */
  padding: 8px; /* Adjust padding to ensure content is centered and looks nice */
  border-radius: 20px; /* Apply a large border-radius for a rounded appearance */
  border: 1px solid #C9C9C9; /* Optional: add a border to match your design */
  background-color: white; /* Ensure the background is white or matches your design */
  cursor: pointer; /* Change cursor to pointer to indicate it's selectable */
  -webkit-appearance: none; /* Remove default style on WebKit browsers */
  -moz-appearance: none; /* Remove default style on Mozilla browsers */
  appearance: none; /* Standard way to remove default styling */
  font-family: Montserrat, sans-serif; /* Ensure text matches your design */
  font-size: 12px; /* Adjust font size as needed */
}

/* Style for adding an arrow icon to the select element */
.select-arrow {
  pointer-events: none; /* Ignore pointer events */
  position: absolute; /* Position it within the parent element */
  right: 10px; /* Position it from the right */
  top: 50%; /* Center it vertically */
  transform: translateY(-50%); /* Adjust vertical positioning */
  color: #C9C9C9; /* Match the arrow color with the select border */
}

/* Adjust the container of the select to position the arrow correctly */
.image-button {
  position: relative; /* Establish a positioning context for absolute children */
  display: inline-block; /* Treat it as inline-block for proper spacing */
  margin: 0 5px; /* Adjust spacing between buttons */
}


.image-button img {
  height: auto;
  transition: transform 0.3s ease;
}

.image-button:hover img {
  transform: scale(1.1);
}

.image-button:active img {
  transform: scale(0.9);
}

/* Adjust select element styling to fit within the layout */
select {
  /* Your existing select styles */
  margin: 0; /* Ensure there's no extra margin that could disrupt alignment */
}

.loading-animation {
  border: 8px solid #ccc;
  border-radius: 50%;
  border-top: 8px solid #007bff;
  width: 20px;
  height: 20px;
  animation: spin 2s linear;
  margin: 5px auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}