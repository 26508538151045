.search-box {
    min-width: 860px;
    width: auto;
    height: 70px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(30, 147, 253, 0.20);
    margin: 0px 10px 0px 10px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .search-box-dashboard {
    min-width: 800px;
    width: auto;
    height: 70px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(30, 147, 253, 0.20);
    margin: 0px 10px 0px 10px;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .input-field {
    border-radius: 5px;
    border: 1px solid #CCC9C9;
    margin: 4vh;
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: scroll;
  }
  
  .search {
    flex-grow: 1;
    height: 37px;
    border: none;
    outline: none;
  }
  
  .button-container {
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
  }


.button-container:hover {
  transform: scale(1.1);
}

.button-container:active {
  transform: scale(0.9);
}
  
  button {
    padding: 8.38px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    border-color: white;
    color: #FFF;
    border-style: none;
    margin-right: 20px;
    margin-left: 20px;
  }

.specialbackground {
  background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
}

.search-icon {
  padding: 10px 10px 10px 10px;
}