.logs-box {
    max-width: 420px;
    min-width: 250px;
    height: 820px;
    min-height: 425px;
    margin-right: 70px;
    flex-shrink: 0;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0px 10px 40px 0px rgba(30, 147, 253, 0.20);
    overflow: hidden;
    margin-bottom: 10px;
}

.logs-item{
    width: 420px;
    height: 57px;
    flex-shrink: 0;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    text-align: center;
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
    background: #F5F5F5;
}

.logs-title{
    display: flex;
    color: #000;
    margin-left: 34px;
    margin-top: 16x;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    height: 16px;
}

hr{
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}