  .grey {
    background: #F5F5F5;
  }
  
  .action-button {
    margin-right: 9vh;
    display: inline-block;
    position: relative;
  }

  .action-button button {
    margin-left: -25px; /* Adjust this value as needed */
  }
  
  .accounts-informations {
    display: flex;
    align-items: center;
  }
  
  .account-name {
    color: #0A66C2;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

.profile-picture{
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    margin-right: 1vh;
    border-radius: 50%;
}
  
.image-button {
  position: relative;
  overflow: hidden;
  border: none;
  background: none;
  padding: 1;
  cursor: pointer;
}

.image-button img {
  transition: transform 0.3s ease;
}

.image-button:hover img {
  transform: scale(1.1);
  overflow: visible;
}

.image-button:active img {
  transform: scale(0.9);
}

.wrapper-account-item {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: minmax(50px, auto);
  width: auto;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding-top: 5px;
}

.data-account-item-1{
  grid-column: 1;
  margin-left: 5px;
}
.data-account-item-2{
  grid-column: 2;
  margin-left: -100px;
}
.data-account-item-3{
  grid-column: 3/5;
}
.data-account-item-4{
  grid-column: 5/5;
  margin-right: -100px;
}
.data-account-item-5{
  grid-column: 6;
  margin-right: 5px;
}

.account-name img {
  margin-top: 17px
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  color: #000;
  padding: 10px;
  border-radius: 5px 0;
  background: #B1DBFC;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
  overflow: auto;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}