/* General styling for the header row */
.data-client-1{
  flex: 1;
  text-align: left;
  padding: 0 10px;
}

.data-client-2{
  flex: 2;
  text-align: left;
  padding: 0 10px;
}

.data-client-3{
  flex: 2;
  text-align: left;
  padding: 0 10px;
}

.data-client-4{
  flex: 2;
  text-align: left;
  padding: 0 10px;
}

.account-item-box-client {
  display: flex;
  align-items: center; /* Align items vertically within the row */
  justify-content: space-between; /* Space out the child elements evenly */
  padding: 10px 50px; /* Match padding with client items */
  background-color: #EEE; /* Optional: distinguish header with a different background */
  font-weight: bold; /* Optional: make header text bold */
}

/* Styling for each header element */

.account-item-box-client > div:nth-child(1),
.account-item-box-client > div:nth-child(2),
.account-item-box-client > div:nth-child(3) {
  width: calc(60% / 3);
}

.account-item-box-client > div:nth-child(4) {
  width: 40%;
}