.account-box{
    min-width: 860px;
    width: auto;
    max-height: 513px;
    height: auto;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(30, 147, 253, 0.20);
    margin: 10px;
    color: #000;
    overflow: scroll;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

hr {
    background: #C9C9C9;
    padding: 0;
    margin: -5px 0px 5px 0px;
}

.wrapper-account {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: minmax(50px, auto);
    width: auto;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding-top: 5px;
    grid-auto-flow: column; /* Ensure only one row */
  }

  .data-account-1{
    grid-column: 1/2;
  }
  .data-account-3{
    grid-column: 3/5;
  }
  .data-account-4{
    grid-column: 5/6;
    margin-left: 60px;
  }

  .data-account-5{
    grid-column: 6/6;
    margin-left: 15px;
  }