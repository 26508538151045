.modal-confirm {
    width: 350px;
    height: 250px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }

.modal-confirm h1 {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  margin-top: 0px;
  margin-bottom: 35px;
}

.modal-confirm button {
  display: inline-flex;
  padding: 14px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

  .modal-confirm-close-button {
    display: flex;
    align-self: flex-end;
    background: transparent;
    cursor: pointer;
    margin-right: -35px;
    margin-top: -25px;
  }
  
  .modal-confirm-close-button img {
    width: 20px;
    height: 20px;
  }

  .cancel {
    color: #3153C7;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 0.838px solid #1E93FD;
  }
