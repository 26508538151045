/* dashboard */

/* dashboard account section */
@media (max-width: 900px) {
  /* Adjust layout for screens smaller than 600px */
  div > div:nth-child(2) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  div > div:nth-child(2) > div {
    width: 100%;
    margin-bottom: 10px;
  }
}

.client-header {
  border: solid 1px #1E93FD;
  border-radius: 8px;
  margin: 10px;
}

.client-header p {
  color: #0A66C2;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  margin-left: 10px;
}