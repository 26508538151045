.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .modal {
    width: 550px;
    height: 425px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-edit {
    width: 400px;
    align-items: left;
  }

  .modal-edit input {
    border-radius: 5px;
    border: 1px solid #1E93FD;
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
  }

  .modal-edit p {
    display: flex;
    width: 451px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    margin-top: 20px;
    gap: 6px;
  }

.modal-edit input {
  width: 90%;
}

.modal-edit h1 {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
}
  
  .action-button-edit {
    transition: transform 0.3s ease;
    cursor: pointer;
    display: flex;
    width: 287px;
    padding: 14px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 5px;
    border-radius: 50px;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .red{
    background: #FA5D72;
  }
  
.action-button-edit:hover {
    transform: scale(1.1);
    overflow: visible;
  }
  
.action-button_EDIT:active {
    transform: scale(0.9);
  }

  .modal-edit-close-button {
    display: flex;
    align-self: flex-end;
    background: transparent;
    cursor: pointer;
    margin-right: -35px;
    margin-top: -25px;
  }
  
  .modal-edit-close-button img {
    width: 20px;
    height: 20px;
  }