.flex-header {
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    padding: 19px 19px 6px 20px;
  }
  .back_button {
    width: 125px;
    display: flex;
    cursor:pointer;
  }

  .hidden {
    visibility: hidden;
  }